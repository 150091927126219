import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=1a77d726&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=1a77d726&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a77d726",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderLogo: require('/home/ticketshoplv/public_html/nervy.tour.tickets/components/Header/Logo.vue').default})
