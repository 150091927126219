import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0953ddab = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _b1521352 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _eb54c754 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _e5e9f6b0 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _0aae4693 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _5a08fe7c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4cf4bffe = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _34b2983c = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _21f61bc5 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _00046e4c = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _54a76b2c = () => interopDefault(import('../pages/restore/index.vue' /* webpackChunkName: "pages/restore/index" */))
const _66329dc2 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _094ccd00 = () => interopDefault(import('../pages/tickets.vue' /* webpackChunkName: "pages/tickets" */))
const _fb5026cc = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _9ff08424 = () => interopDefault(import('../pages/categories/_cid/index.vue' /* webpackChunkName: "pages/categories/_cid/index" */))
const _03a6fed3 = () => interopDefault(import('../pages/events/_eid/index.vue' /* webpackChunkName: "pages/events/_eid/index" */))
const _98244114 = () => interopDefault(import('../pages/groups/_id.vue' /* webpackChunkName: "pages/groups/_id" */))
const _2402ff7d = () => interopDefault(import('../pages/payment/_hash.vue' /* webpackChunkName: "pages/payment/_hash" */))
const _6dfa011a = () => interopDefault(import('../pages/refund/_id.vue' /* webpackChunkName: "pages/refund/_id" */))
const _097f8838 = () => interopDefault(import('../pages/restore/_key.vue' /* webpackChunkName: "pages/restore/_key" */))
const _4204a3f0 = () => interopDefault(import('../pages/unsubscribe/_hash.vue' /* webpackChunkName: "pages/unsubscribe/_hash" */))
const _894777b0 = () => interopDefault(import('../pages/events/_eid/tickets.vue' /* webpackChunkName: "pages/events/_eid/tickets" */))
const _079d3c63 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _0953ddab,
    name: "index___en"
  }, {
    path: "/ru",
    component: _0953ddab,
    name: "index___ru"
  }, {
    path: "/en/cart",
    component: _b1521352,
    name: "cart___en"
  }, {
    path: "/en/categories",
    component: _eb54c754,
    name: "categories___en"
  }, {
    path: "/en/cookies",
    component: _e5e9f6b0,
    name: "cookies___en"
  }, {
    path: "/en/events",
    component: _0aae4693,
    name: "events___en"
  }, {
    path: "/en/login",
    component: _5a08fe7c,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _4cf4bffe,
    name: "logout___en"
  }, {
    path: "/en/payment",
    component: _34b2983c,
    name: "payment___en"
  }, {
    path: "/en/profile",
    component: _21f61bc5,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _00046e4c,
    name: "register___en"
  }, {
    path: "/en/restore",
    component: _54a76b2c,
    name: "restore___en"
  }, {
    path: "/en/search",
    component: _66329dc2,
    name: "search___en"
  }, {
    path: "/en/tickets",
    component: _094ccd00,
    name: "tickets___en"
  }, {
    path: "/ru/cart",
    component: _b1521352,
    name: "cart___ru"
  }, {
    path: "/ru/categories",
    component: _eb54c754,
    name: "categories___ru"
  }, {
    path: "/ru/cookies",
    component: _e5e9f6b0,
    name: "cookies___ru"
  }, {
    path: "/ru/events",
    component: _0aae4693,
    name: "events___ru"
  }, {
    path: "/ru/login",
    component: _5a08fe7c,
    name: "login___ru"
  }, {
    path: "/ru/logout",
    component: _4cf4bffe,
    name: "logout___ru"
  }, {
    path: "/ru/payment",
    component: _34b2983c,
    name: "payment___ru"
  }, {
    path: "/ru/profile",
    component: _21f61bc5,
    name: "profile___ru"
  }, {
    path: "/ru/register",
    component: _00046e4c,
    name: "register___ru"
  }, {
    path: "/ru/restore",
    component: _54a76b2c,
    name: "restore___ru"
  }, {
    path: "/ru/search",
    component: _66329dc2,
    name: "search___ru"
  }, {
    path: "/ru/tickets",
    component: _094ccd00,
    name: "tickets___ru"
  }, {
    path: "/en/profile/tickets",
    component: _fb5026cc,
    name: "profile-tickets___en"
  }, {
    path: "/ru/profile/tickets",
    component: _fb5026cc,
    name: "profile-tickets___ru"
  }, {
    path: "/en/categories/:cid",
    component: _9ff08424,
    name: "categories-cid___en"
  }, {
    path: "/en/events/:eid",
    component: _03a6fed3,
    name: "events-eid___en"
  }, {
    path: "/en/groups/:id?",
    component: _98244114,
    name: "groups-id___en"
  }, {
    path: "/en/payment/:hash",
    component: _2402ff7d,
    name: "payment-hash___en"
  }, {
    path: "/en/refund/:id?",
    component: _6dfa011a,
    name: "refund-id___en"
  }, {
    path: "/en/restore/:key",
    component: _097f8838,
    name: "restore-key___en"
  }, {
    path: "/en/unsubscribe/:hash?",
    component: _4204a3f0,
    name: "unsubscribe-hash___en"
  }, {
    path: "/ru/categories/:cid",
    component: _9ff08424,
    name: "categories-cid___ru"
  }, {
    path: "/ru/events/:eid",
    component: _03a6fed3,
    name: "events-eid___ru"
  }, {
    path: "/ru/groups/:id?",
    component: _98244114,
    name: "groups-id___ru"
  }, {
    path: "/ru/payment/:hash",
    component: _2402ff7d,
    name: "payment-hash___ru"
  }, {
    path: "/ru/refund/:id?",
    component: _6dfa011a,
    name: "refund-id___ru"
  }, {
    path: "/ru/restore/:key",
    component: _097f8838,
    name: "restore-key___ru"
  }, {
    path: "/ru/unsubscribe/:hash?",
    component: _4204a3f0,
    name: "unsubscribe-hash___ru"
  }, {
    path: "/en/events/:eid/tickets",
    component: _894777b0,
    name: "events-eid-tickets___en"
  }, {
    path: "/ru/events/:eid/tickets",
    component: _894777b0,
    name: "events-eid-tickets___ru"
  }, {
    path: "/en/:slug",
    component: _079d3c63,
    name: "slug___en"
  }, {
    path: "/ru/:slug",
    component: _079d3c63,
    name: "slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
