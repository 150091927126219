import { render, staticRenderFns } from "./Compact.vue?vue&type=template&id=c297e0a2&scoped=true&"
import script from "./Compact.vue?vue&type=script&lang=js&"
export * from "./Compact.vue?vue&type=script&lang=js&"
import style0 from "./Compact.vue?vue&type=style&index=0&id=c297e0a2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c297e0a2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventCardImage: require('/home/ticketshoplv/public_html/nervy.tour.tickets/components/Event/CardImage.vue').default})
